/* Welcome.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F4F9;
  font-family: 'Roboto', sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.container {
  width: 1040px;
  height: 424px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  display: flex;
  padding: 36px;
  position: relative;
}

.left-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}

.logo-container {
  width: 78px;
  height: 78px;
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -20px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text-container {
  margin-bottom: 24px;
}

.sign-in-text {
  color: #1f1f1f;
  font-weight: 400;
  font-size: 3rem;
  margin-bottom: 8px;
  margin-left: -7px;
}

.dropdown-container {
  display: inline-flex; /* Use inline-flex to fit content */
  align-items: center;
  padding: 4px 8px; /* Adjust padding to change size */
  background-color: #FFFFFF; /* Normal color */
  border: 1px solid #747775; /* Add border */
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto; /* Adjust width to fit content */
}

.dropdown-container:hover {
  background-color: #F1F1F1; /* Hover color */
}

.dropdown-content {
  display: flex;
  align-items: center;
}

.profile-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.email {
  color: #1f1f1f;
  font-weight: 400;
  font-size: 1rem;
  margin-right: 8px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}

.dropdown-arrow {
  width: 24px;
  height: 24px;
}

.right-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  padding-top: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-container {
  position: relative;
  width: 458px;
  height: 54px;
  margin-bottom: 16px;
}

.input-container.message {
  height: 150px;
  margin-top: 2px;
}

.input {
  width: 100%;
  height: 100%;
  padding: 13px 15px;
  border: 1px solid #747775;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s ease-in-out, border-width 0.2s ease-in-out;
  box-sizing: border-box;
}

.input:focus {
  border-color: #0b57d0;
  border-width: 2px;
}

.input-label {
  position: absolute;
  top: 14px;
  left: 16px;
  color: #1f1f1f;
  font-size: 1rem;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.input:focus::placeholder {
  color: transparent;
}

.input:focus + .input-label,
.input:not(:placeholder-shown) + .input-label {
  top: -12px;
  left: 14px;
  background-color: #fff;
  padding: 0 4px;
  font-size: 0.75rem;
  color: #0b57d0;
}

.input:focus + .input-label::before,
.input:not(:placeholder-shown) + .input-label::before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: #fff;
  z-index: 1;
  transform: translateY(-50%);
}

.input-container.error .input {
  border-color: #B3261E;
}

.input-container.error .input-label {
  color: #B3261E;
}

.input-container.error .input:focus + .input-label {
  color: #B3261E; /* Ensure label stays red on focus */
}

.word-count {
  font-size: 12px;
  color: #747775;
  margin-top: 4px;
  text-align: right;
}

.error-message {
  font-size: 12px;
  color: #B3261E;
  display: flex;
  align-items: center;
  margin-top: 4px; /* Adjust margin as needed */
  margin-bottom: 2px;
}

.error-message .error-icon {
  margin-right: 8px;
}

.error-message.subject-error {
  margin-top: 17px; /* Adjust this margin to position correctly */
}

.error-message.message-error {
  margin-top: 62px; /* Adjust this margin to position correctly */
}

.next-button {
  background-color: #0B57D0;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 26px;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  bottom: 36px;
  right: 36px;
}

.next-button:hover {
  background-color: #0E4EB9;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  html, body {
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
  }

  body {
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .container {
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 20px;
    border-radius: 0;
    box-shadow: none;
    align-items: center;
  }

  .left-section,
  .right-section {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .logo-container {
    margin: 0 auto 20px;
  }

  .sign-in-text {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 8px;
  }

  .dropdown-container {
    text-align: center;
    margin-bottom: 16px;
  }

  .input-group {
    width: 100%;
    align-items: center;
  }

  .input-container {
    width: 90%;
    margin-bottom: 8px;
  }

  .input-container.message {
    height: 150px;
  }

  .next-button {
    bottom: 20px;
    right: 20px;
  }
}
