.confirmation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  .confirmation-container h1 {
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  .confirmation-container p {
    font-size: 1rem;
  }
  